@font-face {
  font-family: 'Albra-Light';
  src: url('assets/fonts/webfonts/3AFA95_0_0.eot');
  src: url('assets/fonts/webfonts/3AFA95_0_0.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/webfonts/3AFA95_0_0.woff2') format('woff2'),
  url('assets/fonts/webfonts/3AFA95_0_0.woff') format('woff'),
  url('assets/fonts/webfonts/3AFA95_0_0.ttf') format('truetype');
}
@font-face {
  font-family: 'Albra-Medium';
  src: url('assets/fonts/webfonts/3B1305_1_0.eot');
  src: url('assets/fonts/webfonts/3B1305_1_0.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/webfonts/3B1305_1_0.woff2') format('woff2'),
  url('assets/fonts/webfonts/3B1305_1_0.woff') format('woff'),
  url('assets/fonts/webfonts/3B1305_1_0.ttf') format('truetype');
}
@font-face {
  font-family: 'IsidoraSans-Bold';
  src: url('assets/fonts/webfonts/3AFA13_0_unhinted_0.woff2') format('woff2'),
  url('assets/fonts/webfonts/3AFA13_0_unhinted_0.woff') format('woff'),
  url('assets/fonts/webfonts/3AFA13_0_unhinted_0.ttf') format('truetype');
}

html {
  max-height: 100%;
}

body {
  margin: 0;  
  font-family: Albra-Light;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  max-height: calc(100% - 100vh);
  transition-timing-function: ease-in;
}

body.loaded, body.menu-open {
  transition: 0.8s cubic-bezier(0.77, 0, 0.175, 1);
  transform-origin: top;
  overflow-y: scroll;
}

body.loaded {
  transform: translateY(-100vh);
}

body.menu-open, body.projectPage.projectPageOpen.menu-open {
  overflow: hidden;
  transform: translateY(0vh);
}

body.projectPage.projectPageOpen {
  padding-right: 0px;
  height: auto;
  overflow-y: scroll;
}


body.projectPage {
  position: relative !important;
  padding-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
  margin-left: 0;
  margin-top: 0;
  height: 100vh;
  overflow-y: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  color: #161616;
  margin-top: 0px;
}

h1::selection,
h2::selection,
h3::selection,
h4::selection,
h5::selection,
h6::selection,
p::selection,
div::selection,
img::selection,
ul::selection,
li::selection,
span::selection, 
a::selection,
br::selection,
button::selection {
  background: #FF6969;
  color: #fff;
}

p {
  font-size: 18px;
}

/* section {
  padding: 15vh 0;
} */

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a,
a:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.noPadding {
  padding: 0
}

.grid-55,
.grid-50,
.grid-45 {
  position: relative;
  display: inline-block;  
}

.grid-55 {
  width: 55%;
  position: relative;
}

.grid-50 {
  width: 50%;
  position: relative;
}

.grid-45 {
  width: 45%;
  position: relative;
}

img {
  max-width: 100%;
}

button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

button.simpleButton {
  border: none;
  background: none;
  font-family: Albra-Light;
  font-size: 18px;
  text-transform: lowercase;
  margin-top: 30px;
  cursor: pointer;
  padding: 0px;
  position: relative;
  transform: skewX(-9deg);
}

button.simpleButton:hover::before {
  right: -40px;
}

button.simpleButton::before {
  content: '';
  width: 18px;
  height: 100%;
  position: absolute;
  right: -35px;
  background-image: url(../src/assets/images/graphics/arrow-right-black@2x.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: skewX(9deg);
  transition: 0.3s;
}

button.simpleButton::after {
  content: '';
  border-bottom: 1px solid #161616;
  width: 150%;
  height: 2px;
  position: absolute;
  bottom: -7px;
  left: 0px;
}

button:focus {
  outline: none;
}

/* SCREEN TOO SMALL */
div.screenTooSmall {
  width: 100vw;
  height: 100vh;
  background-color: #161616;
  display: flex;
  align-items: center;
}

div.screenTooSmall .content {
  display: inline-block;
  margin: 0 auto;
  max-width: 300px;
}

div.screenTooSmall h6 {
  margin: auto;
  font-family: 'VT323', monospace;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 18px;
  position: relative;
  z-index: 1;
  color: #eb4543;
  text-align: center;
}

div.screenTooSmall h6:first-of-type {
  margin-bottom: 10px;
}

@media only screen and (max-width: 1080px) {
  body.projectPage {
    padding-right: 0px;
  }

  p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .mobile-grid-100 {
    width: 100%;
  }

  button.simpleButton {
    font-size: 16px;
  }
}
 