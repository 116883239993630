.logo {
  width: 120px;
  height: 30px;
  top: 80px;
  left: calc((100vw - 120px)/ 2);
  position: absolute;
  z-index: 23;
}

.logo svg {
  max-width: 100%;
  max-height: 100%;
  fill: #FFFFFF;
}

@media only screen and (max-width: 1080px) {
  .logo {
    top: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .logo {
    transform: scale(0.8);
    top: 50px;
  }
}

@media only screen and (max-height: 550px) and (min-width: 1080px) {
  .logo {
    top: 30px;
  }
}