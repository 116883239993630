.projectListSection {
  padding: 300px 0px;
  position: relative;
  opacity: 1;
}

.projectsList {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
}

.projectItem {
  height: 365px;
  width: 33.33%;
  display: inline-block;
  padding: 20px;
  box-sizing: border-box;
  transform: scale(1) translate3d(0px, 0px, 0px);
  transition: transform .4s cubic-bezier(0.77, 0, 0.175, 1);
}

.projectsList .projectItem:hover {
  transform: scale(1.1) translate3d(0px, 0px, 0px);
  transition: transform .4s cubic-bezier(0.77, 0, 0.175, 1);
}

.projectItem button {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover;
  cursor: pointer;
  display: block;
  background-position: center;
}

@media only screen and (max-width: 1080px) {
  .projectListSection {

  }
}
  
@media only screen and (max-width: 768px) {
  .projectListSection {
    padding: 100px 0px;
  }

  .projectItem {
    height: 365px;
    width: 100%;
    display: block;
    padding: 20px;
    box-sizing: border-box;
    transform: scale(1) translate3d(0px, 0px, 0px);
    transition: transform .4s cubic-bezier(0.77, 0, 0.175, 1);
  }
}