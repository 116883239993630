.socialIcons {
  margin: 0px;
  list-style: none;
}

footer .socialIcons {
  margin-top: 0px;
}
  
.socialIcons li {
  opacity: 0.4;
  transition: 0.3s;
  text-align: center;
}

.socialIcons li:hover {
  opacity: 1;
}

.socialIcons li a {
  display: block;
}

footer .socialIcons a:after {
  display: none;
}

.socialIcons li img {
  max-height: 20px;
  display: block;
  margin: 0 auto;
  padding: 5px;
  border: 2px solid transparent;
  border-radius: 11px;
  transition: all .5s cubic-bezier(0.77, 0, 0.175, 1);
}

.socialIcons li:hover img {
  border: 2px solid;
}

.socialIcons:global(.vertical) li {
  margin-bottom: 20px;
}

.socialIcons:global(.horizontal) li {
  display: inline-block;
  margin-right: 22px;
}