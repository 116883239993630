.projectPageContent {
  width: 76vw;
  margin: 0 auto;
  padding: 200px 0px;
  position: relative;
  z-index: 2;
}

.projectContent p {
  font-size: 28px;
  max-width: 700px;
  margin-right: 200px;
  float: left;
  margin-top: 0px;
}

.projectContent ul {
  float: left;
  margin-top: 10px;
}

.projectContent ul li {
  background-color: #e7ebed;
  color: #999da0;
  padding: 0px 5px 7px 5px;
  font-size: 22px;
  line-height: 1;
  margin-bottom: 4px;
  display: table;
}

.imageGallery img {
  width: 100%;
  margin-top: 100px;
}

@media only screen and (max-width: 1080px) {
  .projectPageContent {
    width: 90vw;
    padding: 100px 0px;
    box-sizing: border-box;
  }

  .imageGallery img {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .projectPageContent {
    padding: 50px 0px;
  }

  .projectContent p {
    font-size: 22px;
    max-width: none;
    margin-right: 0px;
  }
}