.navigation {
  position: absolute;
  top: 90px;
  right: 50px;
  z-index: 23;
}

.navigation.lightTheme {
  color: #fff;
}

.navigation.lightTheme li:hover img {
  border-color: #fff;
}

.navigation.active .menuButton::before {
  transform: rotate(45deg);
  top: 9px;
  background-color: #fff;
}

.navigation.active .menuButton::after {
  transform: rotate(-45deg);
  bottom: 9px;
  background-color: #fff;
}

.menuButton {
  width: 40px;
  height: 20px;
  margin-bottom: 40px;
  position: relative;
  cursor: pointer;
}

.menuButton::before, .menuButton::after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #161616;
  position: absolute;
  transition: 0.3s;
}

.lightTheme .menuButton::before,
.lightTheme .menuButton::after {
  background-color: #fff;
}

.menuButton::before {
  top: 0;
}

.menuButton::after {
  bottom: 0;
}

.menuButton.close::before {
  transform: translateY(8px) rotate(45deg);
}

.menuButton.close::after {
  transform: translateY(-10px) rotate(-45deg);
}

.active.navigation p {
  color: #fff;
}

.navigation p {
  text-transform: lowercase;
  transform: rotate(90deg);
  transition: 0.3s;
}

.menuContainer {
  width: 100vw;
  height: 100vh;
  background-color: #161616;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 21;
  display: flex;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  align-items: center;
}

.menuContentContainer {
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  padding: 0px 40px;
  box-sizing: border-box;
  position: relative;
  top: -50px;
  display: flex;
  align-items: center;
}

.menuContentContainer h2 {
  color: #fff;
  font-size: 78px;
  line-height: 1;
  margin: 0;
}

.menuContent {
  width: 50%;
  max-width: 600px;
  padding-right: 50px;
  box-sizing: border-box;
}

.contactFormContainer {
  padding: 40px 30px;
  padding-top: 15px;
  padding-left: 80px;
  width: 50%;
  float: left;
  box-sizing: border-box;
  border-left: 1px solid #fff;
}

.contactFormContainer form {
  max-width: 500px;
}

.contactFormContainer input {
  display: block;
  width: 100%;
  padding: 16px 20px;
  font-size: 16px;
  box-sizing: border-box;
  background: none;
  border: none;
  color: #fff;
  outline: none;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-bottom: 1px solid #fff;
  font-family: IsidoraSans-Bold;
}

.contactFormContainer input:focus {
  border: 1px solid #fff;
}

.contactFormContainer button {
  width: 100%;
  padding: 16px 20px;
  color: #161616;
  font-family: IsidoraSans-Bold;
  background-color: #fff;
  border: none;
  position: relative;
  margin-top: 40px;
  transition: 0.3s;
  cursor: pointer;
  font-size: 16px;
  text-transform: lowercase;
}

.contactFormContainer button:hover,
.contactFormContainer button:focus {
  background-color: #f8ec25;
}

.contactFormContainer button::after {
  content: '';
  width: 30px;
  height: 30px;
  position: absolute;
  right: -15px;
  bottom: -15px;
  transform: rotate(45deg);
  background-color: #161616;
}

.contactFormContainer h3 {
  font-family: IsidoraSans-Bold;
  font-size: 22px;
  max-width: 300px;
  color: #fff;
}

.contactFormContainer .errorMessage {
  color: #EB4543;
  font-size: 16px;
  font-family: IsidoraSans-Bold;
  padding: 15px 20px;
  margin: 0;
}

@media only screen and (max-width: 1500px) {
  .menuContentContainer {
    top: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .socialsContainer {
    display: none;
  }
}

@media only screen and (max-width: 1080px) {
  .menuContentContainer h2 {
    font-size: 70px;
  }

  .navigation {
    top: 60px;
  }
}

@media only screen and (max-width: 768px) and (max-height: 800px) {
  .navigation {
    right: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .menuContentContainer {
    width: 90%;
    padding: 0px;
  }

  .menuContent {
    max-width: none;
    width: 80%;
  }

  .contactFormContainer {
    width: 100%;
    border: none;
    padding: 0;
  }

  .menuContentContainer h2 {
    font-size: 22px;
  }

  .navigation {
    top: 53px;
    right: 40px;
    transform: scale(0.9);
  }
}

@media only screen and (max-width: 768px) {
  .navigation {
    top: 53px;
  }
}

@media only screen and (max-height: 550px) and (min-width: 1080px) {
  .navigation {
    top: 37px;
  }
}