.aboutSection {
  max-width: 800px;
  margin: auto;
  opacity: 0;
  padding-top: 30vh;
  padding-bottom: 20vh;
  position: relative;
}

.aboutSection p {
  font-size: 45px;
  margin: 0;
  text-align: center;
}

@media only screen and (max-width: 1080px) {

}

@media only screen and (max-width: 768px) {
  .aboutSection {
    padding-top: 200px;
    padding-bottom: 50px;
  }

  .aboutSection p {
    font-size: 28px;
  }
}