.services {
  padding-top: 30vh;
  margin-bottom: 20vh;
  max-width: 1400px;
  margin: auto;
  position: relative;
}

.services::before {
  content: '';
  width: 900px;
  height: 800px;
  position: absolute;
  left: -500px;
  bottom: -260px;
  background-image: url(/static/media/shape-graphic.e53f0a22.svg);
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.3;
}

.titleContainer {
  width: 100%;
  margin-bottom: 20vh;
  box-sizing: border-box;
  display: inline-block;
}

.services h1 {
  font-size: 140px;
  line-height: 1;
  text-transform: lowercase;
  font-family: Albra-Medium;
  z-index: 1;
  position: relative;
  opacity: 0;
  top: 80px;
  float: right;
  margin: 0;
}

.services h1::before {
  content: '';
  width: 100%;
  height: 50px;
  background-color: #e7ebed;
  position: absolute;
  top: 35%;
  left: 0px;
  z-index: -1;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: 0.3s;
}

.services h1:global(.active)::before {
  transform: scaleY(1);
}

.featuredProjectContent {
  width: 100%;
  display: inline-block;
  margin: auto;
}

@media only screen and (max-width: 1080px) {
    .titleContainer {
        margin-bottom: 100px;
    }
}
    
@media only screen and (max-width: 768px) {
  .titleContainer {
      padding-left: 0px;
      margin-left: 0px;
      margin-bottom: 30px;
  }

  .services h1 {
      font-size: 70px;
  }

  .services h1::before {
      height: 35px;
  }

  :global(.projectImage) {
      width: 100%;
  }

  :global(.projectDescription) {
      width: 100%;
      padding-right: 20px;
      position: static;
      order: 2;
      margin-top: 80px;
  }

  .content {
      max-width: 380px;
      float: none;
  }
}