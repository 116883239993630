header.header {
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}

header.header.dark {
  background: #161616;
}

.navigation {
  position: absolute;
  top: 90px;
  right: 50px;
  z-index: 11;
}

.navigation.active .menuButton::before {
  transform: rotate(45deg);
  top: 9px;
  background-color: #fff;
}

.navigation.active .menuButton::after {
  transform: rotate(-45deg);
  bottom: 9px;
  background-color: #fff;
}

.menuButton {
  width: 40px;
  height: 20px;
  margin-bottom: 40px;
  position: relative;
}

.menuButton::before, .menuButton::after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #161616;
  position: absolute;
  transition: 0.3s;
}

.menuButton::before {
  top: 0;
}

.menuButton::after {
  bottom: 0;
}

.socialsContainer {
  position: relative;
  top: 30px;
  left: -15px;
}

.active.navigation p {
  color: #fff;
}

.navigation p {
  text-transform: lowercase;
  transform: rotate(90deg);
  transition: 0.3s;
}

.menuContainer {
  width: 100vw;
  height: 100vh;
  background-color: #161616;
  position: absolute;
  top: -100%;
  left: 0;
  z-index: 90;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  transition-delay: 0.8s;
}

:global(.loaded) .menuContainer {
  opacity: 1;
  visibility: visible;
}

.menuContainer::after {
  content: '';
  width: 100%;
  height: 340px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: url(/static/media/texture-pattern@2x.4c25f8c6.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 2500px;
  opacity: 0.3;
}

.menuContentContainer {
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  padding: 0px 30px;
  margin-top: -150px;
  box-sizing: border-box;
  position: relative;
  top: -100px;
}

.menuContentContainer h2 {
  color: #fff;
  font-size: 78px;
  line-height: 1;
  margin-top: 0px;
}

.menuContent {
  width: 40%;
  max-width: 600px;
  padding-right: 50px;
  box-sizing: border-box;
  float: left;
}

.contactFormContainer {
  padding: 40px 30px;
  padding-top: 15px;
  padding-left: 80px;
  width: 60%;
  float: left;
  box-sizing: border-box;
  border-left: 1px solid #fff;
}

.contactFormContainer form {
  max-width: 500px;
}

.contactFormContainer input {
  display: block;
  width: 100%;
  padding: 16px 20px;
  font-size: 16px;
  box-sizing: border-box;
  background: none;
  border: none;
  color: #fff;
  margin-bottom: 20px;
  border-bottom: 1px solid #fff;
  font-family: IsidoraSans-Bold;
}

.contactFormContainer button {
  width: 100%;
  padding: 16px 20px;
  color: #161616;
  font-family: IsidoraSans-Bold;
  background-color: #fff;
  border: none;
  position: relative;
  margin-top: 40px;
  transition: 0.3s;
  cursor: pointer;
  font-size: 16px;
  text-transform: lowercase;
}

.contactFormContainer button:hover {
  background-color: #f8ec25;
}

.contactFormContainer button::after {
  content: '';
  width: 30px;
  height: 30px;
  position: absolute;
  right: -15px;
  bottom: -15px;
  transform: rotate(45deg);
  background-color: #161616;
}

@media only screen and (max-width: 1080px) {
  .navigation {
    top: 60px;
  }
}

@media only screen and (max-width: 768px) {
  .navigation {
    top: 53px;
    right: 20px;
    transform: scale(0.9);
  }
}