.serviceListContainer {
  float: left;
  padding: 0px 60px;
  box-sizing: border-box;
  position: relative;
}

.serviceListContainer h5 {
  font-family: IsidoraSans-Bold;
  text-transform: lowercase;
  font-size: 22px;
  position: absolute;
  left: 25px;
  bottom: -12px;
  transform: rotate(-90deg);
  transform-origin: left;
  margin: 0px;
}

.serviceListContainer .serviceItem {
  padding: 20px;
  padding-left: 25px;
  border-bottom: 1px solid #161616;
  position: relative;
  transition: 0.2s;
}

.serviceListContainer .serviceItem:hover {
  opacity: 1;
}

.serviceListContainer .serviceItem::before {
  content: '';
  width: 3px;
  height: 3px;
  position: absolute;
  top: calc(100% / 2);
  left: 0;
  border-radius: 50%;
  background-color: #161616;
}

@media only screen and (max-width: 1080px) {
  .serviceListContainer {
    padding: 20px;
  }

  .serviceListContainer h5 {
    position: static;
    transform: none;
    margin: 0px;
  }
}
    
@media only screen and (max-width: 768px) {
  .serviceListContainer {
    margin-bottom: 80px;
    padding: 0px;
  }
}