.featuredProject {
  padding-top: 10vh;
}

.titleContainer {
  width: 100%;
  padding-left: 100px;
  margin-bottom: 20vh;
}

.featuredProject h1 {
  font-size: 140px;
  line-height: 1;
  text-transform: lowercase;
  font-family: Albra-Medium;
  z-index: 1;
  position: relative;
  display: inline-block;
  opacity: 0;
  top: 80px;
  margin: 0;
}

.featuredProject h1::before {
  content: '';
  width: 100%;
  height: 50px;
  background-color: #e7ebed;
  position: absolute;
  top: 35%;
  left: 0px;
  z-index: -1;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: 0.3s;
}

.featuredProject h1:global(.active)::before {
  transform: scaleY(1);
}

.featuredProjectContent {
  display: flex;
  position: relative;
}

.projectContainer {
  width: 100%;
}

.featuredProject h5 {
  font-family: IsidoraSans-Bold;
  text-transform: lowercase;
  position: relative;
  font-size: 20px;
}

.featuredProject h5::before {
  content: '';
  width: 70%;
  height: 6px;
  background-color: #161616;
  position: absolute;
  top: -10px;
  left: 0px;
}

:global(.projectDescription) {
  width: 30%;
  display: inline-block;
  position: absolute;
  bottom: -30px;
  padding-right: 50px;
  box-sizing: border-box;
  margin-bottom: 15px;
  opacity: 0;
}

.content {
  max-width: 380px;
  float: right;
}

:global(.projectImage) {
  width: 70%;
  float: right;
  position: relative;
}

:global(.projectImage) span.tagCover {
  overflow: hidden;
  height: 0;
  transition: 0.3s;
  transition-timing-function: ease-out;
  display: inline-block;
  position: absolute;
  top: -20px;
  right: 10%;
  z-index: 1;
  transform-origin: bottom;
}

:global(.active):global(.projectImage) span.tagCover {
  height: 100px;
}

:global(.projectImage) span.tagCover span {
  padding: 0px 5px;
  padding-bottom: 2px;
  background-color: #e7ebed;
  font-size: 32px;
  color: #c4c4c4;
  font-family: 'VT323', monospace;
}

.featureImageContainer {
  width: 100%;
  height: 900px;
  position: relative;
  overflow: hidden;
}

.featureImageContainer::after {
  content: '';
  width: 250px;
  height: 250px;
  background-color: #fff;
  position: absolute;
  right: -130px;
  bottom: -130px;
  transform: rotate(45deg);
}

:global(.projectImage) :global(.featureImage) {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: left;
  transform: translateX(-101%);
}

@media only screen and (max-width: 1080px) {
  .titleContainer {
    margin-bottom: 100px;
  }

  :global(.projectDescription) {
    width: 35%;
    padding-right: 20px;
  }

  :global(.projectImage) :global(.featureImage) {
    background-position: center;
  }

  :global(.projectImage) {
    width: 65%;
  }
}
  
@media only screen and (max-width: 768px) {
  .featuredProject {
    padding-top: 50px;
  }

  .titleContainer {
    padding-left: 0px;
    margin-left: 0px;
    margin-bottom: 30px;
  }

  .projectContainer {
    display: inline-grid;
  }

  .featuredProject h1 {
    font-size: 70px;
  }

  .featuredProject h1::before {
    height: 35px;
  }

  :global(.projectImage) {
    width: 100%;
  }

  .featureImageContainer {
    height: 600px;
  }

  .featureImageContainer::after {
    width: 150px;
    height: 150px;
    right: -75px;
    bottom: -75px;
  }

  :global(.projectDescription) {
    width: 100%;
    padding-right: 20px;
    position: static;
    order: 2;
    margin-top: 80px;
  }

  .content {
    max-width: 380px;
    float: none;
  }
}