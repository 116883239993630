.loadingContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #161616;
  color: #fff;
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 25;
  transition-delay: 0.8s;
}

:global(.loaded) .loadingContainer {
  opacity: 0;
  visibility: hidden;
}

.loaderContentContainer {
  margin: 0 auto;
  position: relative;
  width: 100%;
}

.loadingContainer h1 {
  color: #eb4543;
  font-size: 140px;
  font-family: Albra-Light;
  margin-left: 70px;
    position: absolute;
}

.loaderContentContainer h6 {
  font-family: 'IsidoraSans-Bold', monospace;
  text-transform: uppercase;
  letter-spacing: 10px;
  font-size: 30px;
  position: relative;
  z-index: 1;
  color: #eb4543;
}

.loadingContainer::after {
  content: '';
  width: 1px;
  height: 50%;
  position: absolute;
  left: 50vw;
  bottom: -5%;
  background-color: #eb4543;
}

@media only screen and (max-width: 1080px) {
  .loadingContainer {
    display: block;
  }
  
  .loadingContainer h1 {
    position: static;
    margin: 0 auto;
    margin-top: 30%;
  }

  .loadingContainer::after {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .loadingContainer h1 {
    margin-top: 40%;
    font-size: 110px;
  }
}