footer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10% 15%;
  background: #F8F8F8;
}

footer strong {
  font-weight: normal;
  font-size: 45px;
  margin-bottom: 40px;
  display: block;
  color: #b9b9b9;
}

.tagline {
  line-height: 1.5;
  font-size: 40px;
}

footer a {
  position: relative;
}

footer a:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: black;
  bottom: -1px;
  left: 0;
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

footer a:hover:after {
  opacity: 1;
}

footer .contactDetails {
  vertical-align: top;
}

footer .contactDetails .contact {
  letter-spacing: 1px;
  display: block;
  line-height: 42px;
}

@media only screen and (max-width: 1080px) {
  .navigation {
    top: 60px;
  }
}

@media only screen and (max-width: 768px) {
  footer {
    padding: 10% 0;
  }

  .footerContainer {
    width: 90vw;
    margin: 0 auto;
  }
  
  .tagline {
    line-height: 1.3;
    font-size: 28px;
    margin-bottom: 25px;
  }

  footer h2 {
    margin-bottom: 0px;
  }

  .contactDetails {
    margin-bottom: 20px;
  }

  .socialDetails h2 {
    margin-bottom: 10px;
  }
}