.homePageContent {
  overflow-x: hidden;
}

:global(.menu-open) .homePageContent {
  padding-right: 16px;
}

.headerContainer {
  width: 100%;
  max-width: 1640px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 2;
}

.headerContainer h1 {
  font-size: 80px;
  font-weight: normal;
  font-family: Albra-Light;
  margin: 0;
  line-height: 1;
  text-transform: lowercase;
  padding: 0px 80px;
  text-align: left;
}

.headerContainer ul {
  display: inline-block;
  margin: 0 auto;
  margin-top: 100px;
}

.headerContainer ul li {
  display: inline-block;
  padding: 0px 30px;
  font-size: 32px;
  font-family: 'VT323', monospace;
  letter-spacing: 6px;
  text-transform: uppercase;
}

.projectsButton {
  position: absolute;
  bottom: 80px;
  right: 80px;
  width: 125px;
  height: 125px;
  cursor: pointer;
  z-index: 2;
}
/* PROJECT BUTTON STYLING */
.projectsButton button {
  font-size: 50px;
  font-family: Albra-Medium;
  text-transform: lowercase;
  cursor: pointer;
  position: absolute;
  color: #EB4543;
  line-height: 40px;
  top: -42px;
  left: 20px;
  padding: 0;
  border: none;
  background: none;
}
.projectsButton button span {
  display: block;
  transition: transform .4s cubic-bezier(0.77, 0, 0.175, 1);
}
.projectsButton:hover button span:nth-of-type(1) {
  transform: translate(-102px, 80px);
}
.projectsButton:hover button span:nth-of-type(2) {
  transform: translate(-63px, 40px);
}
.projectsButton:hover button span:nth-of-type(4) {
  transform: translate(23px, -40px);
}
.projectsButton button span:last-of-type {
  float: right;
}
.projectsButton button:focus {
  outline: none;
}

.projectsButton img {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 125px;
}

.homeImageContainer {
  max-width: 1560px;
  height: 900px;
  padding: 0px 80px;
  margin: 0 auto;
  position: relative;
  margin-top: -200px;
  margin-bottom: 200px;
}

.homeImageContainer::after {
  content: '';
  width: 47px;
  height: 243px;
  background: url('../../assets//images/graphics/stitching-pattern-1@2x.png') space 0 0;
  background-size: contain;
  position: absolute;
  right: 57px;
  bottom: 20%;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
}

.backgroundImage::after {
  content: '';
  width: 450px;
  height: 450px;
  background-color: #fff;
  position: absolute;
  top: -225px;
  right: -225px;
  transform: rotate(45deg);
}

.homeImageContainer span.textOverlay {
  font-family: Albra-Medium;
}

.textOverlay {
  width: 100vw;
  height: 300px;
  background-size: contain;
  background-repeat: repeat-x;
  position: relative;
  top: -100px;
}

:global(.bodyContent) {
  max-width: 85vw;
  margin: auto;
  padding: 0px 80px;
}

@media only screen and (max-height: 1080px) and (min-width: 1080px) {
  .headerContainer ul {
    margin-top: 50px;
  }
  
  .headerContainer ul li {
    font-size: 26px;
  }
}

@media only screen and (max-height: 850px) and (min-width: 850px) {
  .homeImageContainer {
    margin-top: -100px;
  }
}

@media only screen and (max-height: 550px) and (min-width: 650px) {
  .headerContainer h1 {
    font-size: 60px;
  }

  .headerContainer ul {
    margin-top: 30px;
  }
  
  .headerContainer ul li {
    padding: 0px 15px;
    font-size: 22px;
  }

  .projectsButton {
    transform: scale(0.8);
    bottom: 10px;
    right: 120px;
  }
}

@media only screen and (max-width: 1080px) {
  :global(.bodyContent) {
    max-width: 90vw;
    padding: 0px;
  }
}

@media only screen and (max-width: 768px) {
  :global(.menu-open) .homePageContent {
    padding-right: 0px;
  }

  .headerContainer {
    top: -80px;
    background-size: 68%;
  }

  .homeImageContainer {
    padding: 0px;
    width: 90vw;
  }

  .homeImageContainer::after {
    right: -25px;
    bottom: 10%;
  }

  .backgroundImage::after {
    width: 200px;
    height: 200px;
    top: -100px;
    right: -100px;
  }

  .headerContainer h1 {
    font-size: 50px;
  }

  .headerContainer ul {
    margin-top: 30px;
  }

  .headerContainer ul li {
    padding: 0px 10px;
    font-size: 22px;
  }

  .projectsButton {
    bottom: 150px;
    right: -10px;
    transform: scale(0.8);
  }
}