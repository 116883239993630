:global(.imageContainer) {
  height: 60vh;
  cursor: pointer;
  z-index: 2;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
}

.backgroundImage:after {
  content: '';
  width: 300px;
  height: 300px;
  display: block;
  position: absolute;
  bottom: -150px;
  right: -150px;
  background-color: #fff;
  transform: rotate(45deg);
  transition: 0.75s;
}

:global(.projectContainer) {
  width: 100vw;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 12vw;
  box-sizing: border-box;
}

/* .imageContainer:hover h1 span:nth-of-type(2) {
  transform: translate(116px, -160px);
}

.imageContainer:hover h1 span:nth-of-type(3) {
  transform: translate(308px, -320px);
} */

:global(.projectContainer) h1 {
  position: absolute;
  left: -25px;
  top: -80px;
  font-size: 200px;
  margin: 0;
  color: #EB4543;
  font-family: Albra-Medium;
  line-height: 0.8;
  mix-blend-mode: hard-light;
}

:global(.projectContainer) h1 span {
  display: block;
  transition: transform .4s cubic-bezier(0.77, 0, 0.175, 1);
}

:global(.projectContainer) img {
  height: 100%;
}

.projectNumber {
  position: absolute;
  font-size: 70px;
  right: 20px;
  top: -55px;
  color: #EB4543;
  text-transform: lowercase;
  font-weight: normal;
  font-family: IsidoraSans-Bold;
}

.projectNumber:after {
  content: '';
  position: absolute;
  width: 58px;
  height: 10px;
  background: white;
  top: 43px;
  right: 0;
}

.projectContent {
  box-sizing: border-box;
  max-width: 550px;
  padding-left: 5vw;
  padding-right: 50px;
  transition: 0.75s;
}

.projectContent h2 {
  font-family: IsidoraSans-Bold;
  text-transform: lowercase;
  font-size: 40px;
  margin: 0px;
  margin-bottom: 5px;
}

.projectSubtitle {
  font-family: Albra-Light;
  margin: 0px;
  margin-bottom: 40px;
  font-size: 24px;
  opacity: 0.3;
  line-height: 1;
}

.tagsList li {
  display: inline;
  margin-right: 10px;
  color: #999DA0;
}

.projectDescription {
  font-family: Albra-Light;
}

:global(.projectPage) :global(.projectPageHeader) {
  visibility: hidden;
}

:global(.projectPageHeader) {
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  padding-left: 100px;
  padding-right: 30px;
  max-width: 76vw;
  box-sizing: border-box;
}

:global(.projectPageHeader) h2 {
  font-family: Albra-Light;
  text-transform: lowercase;
  line-height: 0.8;
  font-size: 60px;
  margin: 0px;
  margin-right: 150px;
}

:global(.projectPageHeader) .projectDescription {
  max-width: 400px;
}

.viewProjectBtn {
  background: none;
  border: none;
  font-family: Albra-Light;
  color: #1c1c1c;
  font-size: 18px;
  text-transform: lowercase;
  transition: 0.3s;
  cursor: pointer;
}

.viewProjectBtn:hover {
  color: #EB4543;
}

.viewProjectBtn:focus {
  outline: none;
}

@media only screen and (max-width: 1800px) {
  .backgroundImage::after {
    width: 250px;
    height: 250px;
    bottom: -125px;
    right: -125px;
  }
}

@media only screen and (max-width: 1500px) {
  .backgroundImage::after {
    width: 150px;
    height: 150px;
    bottom: -75px;
    right: -75px;
  }

  .projectContent {
    padding-left: 3vw;
    max-width: 400px;
  }

  .projectContent h2 {
    font-size: 36px;
  }

  .projectContent h4 {
    font-size: 20px;
  }

  .projectContent p {
    font-size: 16px;
  }

  .projectContent :global(button.simpleButton) {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1080px) {
  :global(.projectContainer) {
    display: block;
    padding-left: 5vw;
  }

  :global(.imageContainer) {
    height: 50vh;
    width: 95%;
    margin-bottom: 5px;
  }

  :global(.projectContainer) h1 {
    font-size: 180px;
    line-height: 0.8;
  }

  /* .backgroundImage:after {
    width: 200px;
    height: 200px;
    bottom: -100px;
    right: -100px;
  } */

  .projectContent {
    max-width: none;
    padding-left: 0px;
    padding-right: 50px;
    width: 100%;
  }

  .projectContent h2 {
    font-size: 32px;
  }

  .projectContent h4 {
    font-size: 22px;
  }

  .projectContentTitles {
    width: 30%;
    float: left;
  }
  
  .projectContentDescription {
    width: 70%;
    padding-left: 0px;
    padding-right: 20px;
    float: left;
    box-sizing: border-box;
    max-width: 500px;
  }

  .projectDescription {
    margin-top: 0px;
  }

  :global(.projectPageHeader) {
    align-items: normal;
  }

  :global(.projectPageHeader) h2 {
    font-size: 32px;
    margin-right: 50px;
  }
}

@media only screen and (max-width: 1500px) {
  :global(.projectPageHeader) {
    padding-left: 30px;
  }

  :global(.projectPageHeader) h2 {
    margin-right: 80px;
  }
}

@media only screen and (max-width: 768px) and (max-height: 800px) {
  :global(.projectContainer) h1 {
    font-size: 80px;
  }
}

@media only screen and (max-width: 768px) {
  :global(.projectPageHeader) {
    display: block;
    padding-left: 0px;
  }

  :global(.projectPageHeader) {
    max-width: 95vw;
  }

  .projectContent h2 {
    font-size: 24px;
  }

  .projectContent h4 {
    font-size: 16px;
  }

  :global(.projectPageHeader) h2 {
    margin-bottom: 20px;
  }

  :global(.projectContainer) h1 {
    left: -10px;
    top: -50px;
    font-size: 125px;
  }

  .projectContentTitles {
    width: 100%;
    float: none;
  }

  .projectContentDescription p {
    display: none;
  }

  .projectNumber {
    transform: scale(0.7);
  }

  .projectSubtitle {
    font-size: 20px;
    margin-bottom: 10px;
  }
}