:global(.projectPageOpen) .projectsContainer {
  padding-right: 0px;
}

.projectsContainer {
  overflow-x: hidden;
}

.projectSlider {
  box-sizing: content-box;
  display: flex;
  width: fit-content;
  padding-top: 50px;
}

.sliderBar {
  width: 100vw;
  height: 7px;
  background-color: #f4f4f4;
  position: absolute;
  bottom: 0px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.sliderBar span {
  width: 33.33%;
  height: 100%;
  background-color: #EB4543;
}

.nextButton {
  position: absolute;
  bottom: 80px;
  right: 80px;
  width: 125px;
  height: 125px;
  z-index: 3;
}

.nextButton button {
  border: none;
  background: none;
  padding: 0;
  text-align: left;
  font-size: 50px;
  font-family: Albra-Medium;
  text-transform: lowercase;
  cursor: pointer;
  position: absolute;
  color: #EB4543;
  line-height: 40px;
  top: -2px;
  left: 35px;
}

.nextButton button span {
  display: block;
  transition: transform .4s cubic-bezier(0.77, 0, 0.175, 1);
}

.nextButton button:hover span:nth-of-type(1) {
  transform: translate(-30px, 40px);
}

.nextButton button:hover span:nth-of-type(3) {
  transform: translate(18px, -40px);
}
.nextButton button span:last-of-type {
  float: right;
}

.nextButton button:focus {
  outline: none;
}

.nextButton img {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 125px;
}

@media only screen and (max-width: 1500px) {
  .nextButton {
    bottom: 40px;
    right: 40px;
    transform: scale(0.8);
  }
}

@media only screen and (min-width: 1080px) {
  .projectsContainer {
    padding-right: 16px;
  }
}

@media only screen and (max-width: 1080px) {
  .nextButton {
    bottom: 20px;
    right: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .nextButton {
    transform: scale(0.7);
  }
}

@media only screen and (max-width: 768px) and (max-height: 800px) {
  .projectSlider {
    padding-top: 0px;
  }

  .nextButton {
    right: -15px;
    bottom: 80px;
    transition: 0.3s;
    transition-delay: 0.3s;
    transition-timing-function: ease-out;
  }

  :global(.projectPageOpen) .nextButton {
    bottom: -30px;
    transition-delay: 0.8s;
  }
}